import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import CategoryRow from "../components/category/CategoryRow";

import "../assets/css/category.css";

export default class CategoriesListPage extends React.Component {

  /**
   * divide a categories list to a structured array to use them
   * in grid layout
   * @param {Array} categories list of all categories
   * @param {Integer} size represent a size of nested array
   * @return {Array} this array contain array of 4 categorie  dimension[4, n]
   */
   formatCategories = (categories, size) => {
     let formatedCategories = [];
     let medArray = [];

     if(categories.length < size) return [categories];


     categories.forEach( ({ node: category }, i) => {
       medArray = [...medArray, category];
       if( (i+1)%size === 0 || categories.length === (i+1) ){
         formatedCategories = [...formatedCategories, medArray]
         medArray = [];
       }

     });

     return formatedCategories;
   }

  render() {
    const { allWcProductsCategories } = this.props.data;
    const categories = this.formatCategories(allWcProductsCategories.edges, 4);
    let categoriesKeys = allWcProductsCategories.edges.map(({ node: category }) => category.name);
    return (
      <Layout
        navigate={this.props.navigate}
        categories={allWcProductsCategories || []}
       >
        <SEO 
          title={`Trouvez touts les catergories`} 
          keywords={[`aalladine`, `ecommerce boutique`, ...categoriesKeys]} />
        <h1 className="content-title container__main-title capitalize">Dicouvrir nos categories</h1>
        <div className="categories-container">
          {
            categories.length && categories.map((categoriesRow, i) => {
              return <CategoryRow key={`categories-row-${i}`} data={categoriesRow} className='row' />
            })
          }
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query {
    allWcProductsCategories (
      filter : { slug : {nin: ["uncategorized", "non-classe"]} },
      sort: { fields: [count], order: DESC }
     ) {
      edges {
       node {
         id
         name
         slug
         count
         display
         description
         _links {
           self{
             href
           }
         }
         image{
           id
           src
           name
           alt
         }
       }
      }
    }
  }
`
