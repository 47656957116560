import React from 'react';

import CategoryItem from "./CategoryItem";

const CategoryRow = ({data, ...props}) => {
  // console.log("data in row", data);
  return (
    <div {...props}>
    {
      data.map(
        (category, i) => <CategoryItem data={category} key={`category-${i}`} className='col s6 m3 p0 center' />
      )
    }
    </div>
  )
}
export default CategoryRow;
