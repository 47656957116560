import React from 'react';
import { Link } from 'gatsby';
import LOGO from '../../assets/images/logo.svg';

import "../../assets/css/category-item.css"
/**
 * CategoryItem component represent a category
 * card with some details about it
 * @param {Object} data list of details about current category
 * @param {Boolean} isNode check if data received contain an object has node attribute
 */
export default ({ data, className=null, ...rest }) => {
  data = 'node' in data ? data.node : data;
  return (
    <div className={`category-item ${className? className: ""}`} {...rest}>
      <Link to={`/category/${data.slug}`} className='category-item-link'>
        <div className="typography-font category-image loading-background js-lazy-background" data-background={data.image ? data.image.src : LOGO}></div>
        <h2 className="typography-font category-name capitalize mb0">{data.name}</h2>
        <p className="typography-font category-description mp0">{data.description}</p>
        <strong className="typography-font category-item-product-count center p0">{data.count}</strong>
      </Link>
    </div>
  )
};
